import React from 'react';
import { StaticQuery } from 'gatsby';

const Notice = () => {
  return (
    <StaticQuery
      query={noticeQuery}
      render={data => {
        const notice = data.site.siteMetadata.notice;
        return (
          <>
            {notice.show
              ? (<div className="top-0 w-full h-12 bg-pink-600 text-white flex items-center justify-center px-8 text-center font-semibold">
                  {notice.message}
                </div>)
              : null
            }
          </>
        )
      }}
    />
  )
};

const noticeQuery = graphql`
  query NoticeBannerQuery {
    site {
      siteMetadata {
        notice {
          message
          show
        }
      }
    }
  }
`;

export default Notice;
