import React from 'react';

const Video = ({ videoSrcURL, videoTitle, ...rest }) => (
  <div className="video h-full">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      {...rest}
    />
  </div>
);

export default Video;
