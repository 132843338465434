import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import FullLayout from '../layouts/full';
import SEO from '../components/SEO';
import AdvantageSection from '../components/sections/AdvantageSection';
import KeyFeatureSection from '../components/sections/KeyFeatureSection';
import PeaceMindSection from '../components/sections/PeaceMindSection';
import SignUpSection from '../components/sections/SignUpSection';
import Modal from '../components/Modal';

const Home = () => {

  const data = useStaticQuery(noticeQuery);
  const notice = data.site.siteMetadata.notice;

  const [isModalOpen, setModalState] = useState(notice.show);

  const closeModal = () => {
    setModalState(false);
  };

  return (
    <FullLayout
      seo={<SEO
        title="iban4u"
        keywords={["iban", "home", "iban4u", "finxp"]} />
      }
    >
      <section className="pb-20 pt-10">
        <div className="container mx-auto px-4 text-center mb-10">
          <h1 className="text-pink-600 font-bold text-5xl">
            What is IBAN4U?
          </h1>
          <p className="text-blue-900">
            IBAN4U is an Online Payment Account ideal for businesses inside and outside the EU, who want to grow their business.<br /><br/>

            This digital account is in EUR currency and comes with a unique IBAN which can also be personalized. No European entity is needed to open an account, which is registered under your company name. You can access the account online, similar to a normal bank account.
          </p>
        </div>

        <AdvantageSection />

      </section>

      <KeyFeatureSection />

      <PeaceMindSection />

      <SignUpSection />

      <Modal
        isOpen={isModalOpen}
        closeModal={() => closeModal()}
        notice={notice}
      />
    </FullLayout>
  )
};

const noticeQuery = graphql`
  query NoticeModalQuery {
    site {
      siteMetadata {
        notice {
          title
          message
          show
        }
      }
    }
  }
`;

export default Home;
